// fonts

@font-face {
    font-family: beelinesans400;
    src: url(../assets/fonts/beelinesans400.ttf);
}

@font-face {
    font-family: beelinesans500;
    src: url(../assets/fonts/beelinesans500.ttf);
}

@font-face {
    font-family: beelinesans600;
    src: url(../assets/fonts/beelinesans600.ttf);
}

@font-face {
    font-family: beelinesans700;
    src: url(../assets/fonts/beelinesans700.ttf);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #E9E9E9;
}

.parent {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    position: sticky;
    overflow: hidden;
}

.UserRouter {
    display: flex;
}

.user_router_child {
    height: auto;
    display: flex;
    flex-direction: column;
    width: calc(100% - 18vw);

    @media (max-width:767px) {
        width: calc(100% - 10vw);
    }
}

.wrapper {
    height: 100%;
    padding: 4vw 13vw;

    @media (max-width:1450px) {
        padding: 5vw 10vw;
    }

    @media (max-width:767px) {
        padding: 5vw 7vw;
    }
}

.p-wrapper {
    height: 100%;
    padding: 2vw 4vw;

    @media (max-width:1450px) {
        padding: 3vw 5vw;
    }

    @media (max-width:767px) {
        padding: 5vw;
    }
}

.for_price {
    padding-top: 1vw;
    padding-bottom: 2vw;

    @media (max-width:1450px) {
        padding-top: 1.5vw;
        padding-bottom: 2.5vw;
    }

    @media (max-width:767px) {
        padding-top: 3vw;
        padding-bottom: 4vw;
    }
}

// gaps //

.gap-05 {
    gap: 0.5vw;

    @media (max-width:767px) {
        gap: 2vw;
    }
}

.gap-1 {
    gap: 1vw;

    @media (max-width:767px) {
        gap: 3vw;
    }
}

.gap-1-5 {
    gap: 1.5vw;

    @media (max-width:767px) {
        gap: 3.5vw;
    }
}

.gap-2 {
    gap: 2vw;

    @media (max-width:767px) {
        gap: 4vw;
    }
}

.gap-2-5 {
    gap: 2.5vw;

    @media (max-width:767px) {
        gap: 4.5vw;
    }
}

.gap-3 {
    gap: 3vw;

    @media (max-width:767px) {
        gap: 5vw;
    }
}

.gap-3-5 {
    gap: 3.5vw;

    @media (max-width:767px) {
        gap: 5.5vw;
    }
}

.gap-4 {
    gap: 4vw;

    @media (max-width:767px) {
        gap: 6vw;
    }
}

.gap-4-5 {
    gap: 4.5vw;

    @media (max-width:767px) {
        gap: 7vw;
    }
}

.gap-5 {
    gap: 5vw;

    @media (max-width:767px) {
        gap: 8vw;
    }
}

// margins //

.mtop-05 {
    margin-top: 0.5vw;

    @media (max-width:767px) {
        margin-top: 2vw;
    }
}

.mtop-1 {
    margin-top: 1vw;

    @media (max-width:767px) {
        margin-top: 3vw;
    }
}

.mtop-1-5 {
    margin-top: 1.5vw;

    @media (max-width:767px) {
        margin-top: 3.5vw;
    }
}

.mtop-2 {
    margin-top: 2vw;

    @media (max-width:767px) {
        margin-top: 4vw;
    }
}

.mtop-2-5 {
    margin-top: 2.5vw;

    @media (max-width:767px) {
        margin-top: 5vw;
    }
}

.mtop-3 {
    margin-top: 3vw;

    @media (max-width:767px) {
        margin-top: 5.5vw;
    }
}

.mtop-3-5 {
    margin-top: 3.5vw;

    @media (max-width:767px) {
        margin-top: 6vw;
    }
}

.mtop-4 {
    margin-top: 4vw;

    @media (max-width:767px) {
        margin-top: 7vw;
    }
}

.mbot-05 {
    margin-bottom: 0.5vw;

    @media (max-width:767px) {
        margin-bottom: 2vw;
    }
}

.mbot-1 {
    margin-bottom: 1vw;

    @media (max-width:767px) {
        margin-bottom: 3vw;
    }
}

.mbot-1-5 {
    margin-bottom: 1.5vw;

    @media (max-width:767px) {
        margin-bottom: 3.5vw;
    }
}

.mbot-2 {
    margin-bottom: 2vw;

    @media (max-width:767px) {
        margin-bottom: 4vw;
    }
}

.mbot-2-5 {
    margin-bottom: 2.5vw;

    @media (max-width:767px) {
        margin-bottom: 5vw;
    }
}

.mbot-3 {
    margin-bottom: 3vw;

    @media (max-width:767px) {
        margin-bottom: 5.5vw;
    }
}

.mbot-3-5 {
    margin-bottom: 3.5vw;

    @media (max-width:767px) {
        margin-bottom: 6vw;
    }
}

.mbot-4 {
    margin-bottom: 4vw;

    @media (max-width:767px) {
        margin-bottom: 7vw;
    }
}

// paddings //

.pd-05 {
    padding: 0.5vw;

    @media (max-width:767px) {
        padding: 1.5vw;
    }
}

.pd-1 {
    padding: 1vw;

    @media (max-width:767px) {
        padding: 2vw;
    }
}

.pd-1-5 {
    padding: 1.5vw;

    @media (max-width:767px) {
        padding: 3vw;
    }
}

.pd-2 {
    padding: 2vw;

    @media (max-width:767px) {
        padding: 4vw;
    }
}

.pd-2-5 {
    padding: 2.5vw;

    @media (max-width:767px) {
        padding: 5vw;
    }
}

.pd-3 {
    padding: 3vw;

    @media (max-width:767px) {
        padding: 6vw;
    }
}

.pd-3-5 {
    padding: 3.5vw;

    @media (max-width:767px) {
        padding: 7vw;
    }
}

.pd-4 {
    padding: 4vw;

    @media (max-width:767px) {
        padding: 8vw;
    }
}

// texts //

.title {
    font-size: 2vw;
    font-weight: 500;
    text-decoration: none;
    font-family: beelinesans600, arial, sans-serif;

    // 42px

    @media (max-width:1450px) {
        font-size: 2.5vw;
    }

    @media (max-width:767px) {
        font-size: 5.8vw;
    }
}

.sub-title {
    font-size: 2.1vw;
    // font-size: 1.5vw;
    font-weight: 500;
    text-decoration: none;
    font-family: beelinesans400, arial, sans-serif;

    // 32px
    @media (max-width:1450px) {
        font-size: 2.5vw;
        // font-size: 2vw;
    }

    @media (max-width:767px) {
        font-size: 4.8vw;
    }
}

.sm-title {
    font-size: 1.5vw;
    font-weight: 500;
    text-decoration: none;
    font-family: beelinesans400, arial, sans-serif;

    // 24px
    @media (max-width:1450px) {
        font-size: 1.9vw;
    }

    @media (max-width:767px) {
        font-size: 4.5vw;
    }
}

.name {
    font-weight: 500;
    font-size: 1.1vw;
    text-decoration: none;
    font-family: beelinesans400, arial, sans-serif;

    // 24px
    @media (max-width:1450px) {
        font-size: 1.5vw;
    }

    @media (max-width:767px) {
        font-size: 4.3vw;
    }
}

.big-text {
    font-size: 0.93vw;
    // font-size: 0.83vw;
    font-weight: 500;
    text-decoration: none;
    font-family: beelinesans400, arial, sans-serif;

    // 18px
    @media (max-width:1450px) {
        font-size: 1.3vw;
    }

    @media (max-width:767px) {
        font-size: 3.8vw;
    }
}

.text {
    font-size: 0.8vw;
    // font-size: 0.7vw;
    font-weight: 400;
    text-decoration: none;
    font-family: beelinesans400, arial, sans-serif;

    // 16px
    @media (max-width:1450px) {
        font-size: 1.1vw;
    }

    @media (max-width:767px) {
        font-size: 3.3vw;
    }
}

.min-text {
    font-size: 0.7vw;
    font-weight: 400;
    text-decoration: none;
    font-family: beelinesans400, arial, sans-serif;

    // 14px
    @media (max-width:1450px) {
        font-size: 0.8vw;
    }

    @media (max-width:767px) {
        font-size: 2.9vw;
    }
}

// selection options

.searchable_select {
    width: 100%;
    font-weight: 400;
    text-decoration: none;
    font-size: 0.93vw !important;
    font-family: beelinesans400, arial, sans-serif;

    // 16px
    @media (max-width:1450px) {
        font-size: 1.1vw !important;
    }

    @media (max-width:767px) {
        font-size: 3.3vw !important;
    }
}

// border radius //

.round-05 {
    border-radius: 0.35vw;
    -webkit-border-radius: 0.35vw;
    -moz-border-radius: 0.35vw;
    -ms-border-radius: 0.35vw;
    -o-border-radius: 0.35vw;

    @media(max-width:1450px) {
        border-radius: 0.5vw;
        -webkit-border-radius: 0.5vw;
        -moz-border-radius: 0.5vw;
        -ms-border-radius: 0.5vw;
        -o-border-radius: 0.5vw;
    }

    @media (max-width:767px) {
        border-radius: 1vw;
        -webkit-border-radius: 1vw;
        -moz-border-radius: 1vw;
        -ms-border-radius: 1vw;
        -o-border-radius: 1vw;
    }
}

.round-07 {
    border-radius: 0.5vw;
    -webkit-border-radius: 0.5vw;
    -moz-border-radius: 0.5vw;
    -ms-border-radius: 0.5vw;
    -o-border-radius: 0.5vw;

    @media(max-width:1450px) {
        border-radius: 0.7vw;
        -webkit-border-radius: 0.7vw;
        -moz-border-radius: 0.7vw;
        -ms-border-radius: 0.7vw;
        -o-border-radius: 0.7vw;
    }

    @media (max-width:767px) {
        border-radius: 1.5vw;
        -webkit-border-radius: 1.5vw;
        -moz-border-radius: 1.5vw;
        -ms-border-radius: 1.5vw;
        -o-border-radius: 1.5vw;
    }
}

.round-1 {
    border-radius: 0.8vw;
    -webkit-border-radius: 0.8vw;
    -moz-border-radius: 0.8vw;
    -ms-border-radius: 0.8vw;
    -o-border-radius: 0.8vw;

    @media(max-width:1450px) {
        border-radius: 1vw;
        -webkit-border-radius: 1vw;
        -moz-border-radius: 1vw;
        -ms-border-radius: 1vw;
        -o-border-radius: 1vw;
    }

    @media (max-width:767px) {
        border-radius: 2.2vw;
        -webkit-border-radius: 2.2vw;
        -moz-border-radius: 2.2vw;
        -ms-border-radius: 2.2vw;
        -o-border-radius: 2.2vw;
    }
}

.round-1-5 {
    border-radius: 1.3vw;
    -webkit-border-radius: 1.3vw;
    -moz-border-radius: 1.3vw;
    -ms-border-radius: 1.3vw;
    -o-border-radius: 1.3vw;

    @media(max-width:1450px) {
        border-radius: 1.5vw;
        -webkit-border-radius: 1.5vw;
        -moz-border-radius: 1.5vw;
        -ms-border-radius: 1.5vw;
        -o-border-radius: 1.5vw;
    }

    @media (max-width:767px) {
        border-radius: 3vw;
        -webkit-border-radius: 3vw;
        -moz-border-radius: 3vw;
        -ms-border-radius: 3vw;
        -o-border-radius: 3vw;
    }
}

.round-2 {
    border-radius: 1.8vw;
    -webkit-border-radius: 1.8vw;
    -moz-border-radius: 1.8vw;
    -ms-border-radius: 1.8vw;
    -o-border-radius: 1.8vw;

    @media(max-width:1450px) {
        border-radius: 2vw;
        -webkit-border-radius: 2vw;
        -moz-border-radius: 2vw;
        -ms-border-radius: 2vw;
        -o-border-radius: 2vw;
    }

    @media (max-width:767px) {
        border-radius: 3.7vw;
        -webkit-border-radius: 3.7vw;
        -moz-border-radius: 3.7vw;
        -ms-border-radius: 3.7vw;
        -o-border-radius: 3.7vw;
    }
}

.round-2-5 {
    border-radius: 2.3vw;
    -webkit-border-radius: 2.3vw;
    -moz-border-radius: 2.3vw;
    -ms-border-radius: 2.3vw;
    -o-border-radius: 2.3vw;

    @media(max-width:1450px) {
        border-radius: 2.5vw;
        -webkit-border-radius: 2.5vw;
        -moz-border-radius: 2.5vw;
        -ms-border-radius: 2.5vw;
        -o-border-radius: 2.5vw;
    }

    @media (max-width:767px) {
        border-radius: 4.5vw;
        -webkit-border-radius: 4.5vw;
        -moz-border-radius: 4.5vw;
        -ms-border-radius: 4.5vw;
        -o-border-radius: 4.5vw;
    }
}

// images

.back-img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

// hover effects //

.scale-05 {
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;

    &:hover {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -o-transform: scale(1.05);
    }

    &:active {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
        -moz-transform: scale(0.95);
        -ms-transform: scale(0.95);
        -o-transform: scale(0.95);
    }
}

.scale-1 {
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;

    &:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
    }

    &:active {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
        -moz-transform: scale(0.95);
        -ms-transform: scale(0.95);
        -o-transform: scale(0.95);
    }
}

.op-05 {
    opacity: 1;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    &:active {
        opacity: 1;
    }
}

.op-07 {
    opacity: 1;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    &:active {
        opacity: 1;
    }
}

.op-08 {
    opacity: 1;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }
}

// contrasts //

.contrast-0 {
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: transparent;
}

.contrast {
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.717);
}

.contrast-05 {
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.438);
}

.dark_global {
    .title {
        color: white;
    }

    .sub-title {
        color: white;
    }

    .sm-title {
        color: white;
    }

    .name {
        color: white;
    }

    .big-text {
        color: white;
    }

    .text {
        color: white;
    }

    .min-text {
        color: white;
    }

    .searchable_select {
        color: white;
    }
}