.SpinnerLoader {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .loader {
        width: 10vw;
        height: 10vw;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        animation-name: loading;
        animation-duration: 2s;
        border: solid 10px dodgerblue;
        border-top-color: transparent;
        border-right-color: transparent;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        @media (max-width:767px) {
            width: 25vw;
            height: 25vw;
            border-width: 6px;
        }
    }

    @keyframes loading {
        100% {
            transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
        }
    }
}